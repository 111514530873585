<template>
    <section>
        <section class="info-container">
            <section class="info-item-wrap">
                <p class="info-title">作业记录</p>
                <a-row class="m-t-18">
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">作业编号</a-col>
                            <a-col class="val">{{taskInfo.no}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">作业类型</a-col>
                            <a-col class="val">{{taskInfo.typeName}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">申请单位</a-col>
                            <a-col class="val">{{taskInfo.applyCompanyName}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">申请人</a-col>
                            <a-col class="val">{{taskInfo.applyPersonName}}</a-col>
                        </a-row>
                    </a-col>
                    
                </a-row>
                <a-row>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">作业时间</a-col>
                            <a-col class="val" v-if="taskInfo.startTime">{{taskInfo.startTime+'~'+taskInfo.endTime}}</a-col>
                        </a-row>
                    </a-col>
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">作业部位</a-col>
                            <a-col class="val">{{taskInfo.position}}</a-col>
                        </a-row>
                    </a-col>
                    
                </a-row>
                <a-row>
                    <a-col :span="16">
                        <a-row class="info-item">
                            <a-col class="key">作业描述</a-col>
                            <a-col class="val">{{taskInfo.description}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    
                    <a-col :span="8">
                        <a-row class="info-item">
                            <a-col class="key">通知人</a-col>
                            <a-col class="val">{{taskInfo.notiPersonName}}</a-col>
                        </a-row>
                    </a-col>
                </a-row>
                <a-row>
                    <a-col :span="16">
                        <a-row class="info-item">
                            <a-col class="key">作业申请表</a-col>
                            <a-col class="val upload-preview-wrap">
                                <a class="file-item m-r-10" v-for="(item,ind) in taskInfo.file" :key="ind">
                                    <preview-image :data="item" :size="100" />
                                </a>
                            </a-col>
                        </a-row>
                    </a-col>
                </a-row>
            </section>
            <section v-if="taskInfo.superviseList && taskInfo.superviseList.length">
                <section class="info-item-wrap" v-for="(item,key) in taskInfo.superviseList" :key="key">
                    <p class="info-title">作业监管-{{item.supervisePersonName}}</p>
                    <a-row class="m-t-18">
                        <a-col :span="8">
                            <a-row class="info-item">
                                <a-col class="key">监管职务</a-col>
                                <a-col class="val">{{item.superviseOccupation}}</a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="8">
                            <a-row class="info-item">
                                <a-col class="key">监管人</a-col>
                                <a-col class="val">{{item.supervisePersonName}}</a-col>
                            </a-row>
                        </a-col>
                        <a-col :span="8">
                            <a-row class="info-item">
                                <a-col class="key">监管时间</a-col>
                                <a-col class="val">{{item.superviseTime}}</a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                    <section class="form-container" style="background:rgba(0,0,0,0.12);padding:16px 10px;border-radius: 4px;position:relative" v-if="actionType == 2 && item.supervisePerson === userId && item.status === 0 ">
                        <a-button style="position:absolute;top:18px;right:10px;z-index:2" type="primary" @click="showSelectUserModal(item)">监管移交</a-button>
                        <section class="form-item">
                            <a-form layout="inline" :form="form">
                                
                                <a-row>
                                    <a-form-item label="监管说明">
                                        <a-textarea placeholder="请输入" v-decorator="['superviseDescription',{rules:[{required: true,message:'请输入监管说明'}]}]" rows="5" style="width:700px"></a-textarea>
                                    </a-form-item>
                                </a-row>
                                <a-row>
                                    <a-form-item label="监管照片">
                                        <a-row type="flex" v-decorator="['supervisePhoto',{rules:[{required:true,message:'请上传监管照片'}]}]">
                                             
                                            <a-row class="upload-preview-wrap">
                                                <a v-for="(file,key) in fileLists" :key="key" class="file-item m-r-10">
                                                    <a-avatar shape="square" :size="100" :src="file.url" />
                                                    <a class="close-icon" title="删除" @click="deleteFile(file)">
                                                        <a-icon type="close"></a-icon>
                                                    </a>
                                                </a>
                                            </a-row>
                                            <upload style="display:inline-block;width:100px" @func="getFile" :node="{type:3,text:'上传'}" :accept="['.png','.jpg']" />
                                        </a-row>
                                    </a-form-item>
                                </a-row>
                            </a-form>
                        </section>
                        <a-row class="form-btn-group">
                            <a-col :span="16" style="padding-left:80px">
                                <a-button @click="submit(item)" :disabled="submitLoadding" :loadding="submitLoadding">
                                    <a-icon type="save" v-if="!submitLoadding" />保存</a-button>
                                <a-button @click="$router.back()">取消</a-button>
                            </a-col>
                        </a-row>
                    </section>
                    <section v-if="item.superviseDescription">
                        <a-row>
                            <a-col :span="16">
                                <a-row class="info-item">
                                    <a-col class="key">监管说明</a-col>
                                    <a-col class="val">{{item.superviseDescription}}</a-col>
                                </a-row>
                            </a-col>
                        </a-row>
                        <a-row>
                            <a-col :span="16">
                                <a-row class="info-item">
                                    <a-col class="key">监管照片</a-col>
                                    <a-col class="val upload-preview-wrap">
                                        <a class="file-item m-r-10" v-for="(url,ind) in item.supervisePhoto" :key="ind">
                                            <preview-image :data="url" :size="100" />
                                        </a>
                                    </a-col>
                                </a-row>
                            </a-col>
                        </a-row>
                    </section>
                </section>
            </section>
        </section>
        <!-- modal -->
        <!-- <a-modal width="1080px" class="modal-container" v-model="selectUserVisible" title="人员列表" :footer="false" :destroyOnClose="true" >
            <select-user :selectMode="'single'" @ok="transferPersonSubmit" @cancel="selectUserVisible = false" />
        </a-modal> -->
        <a-modal centered width="700px" class="modal-container" v-model="selectUserVisible" title="监管移交" :footer="false" :destroyOnClose="true" >
            <a-form :form="transferForm">
               <a-form-item :label-col="{span:4}" :wrapper-col="{span:18}" label="移交人员">
                   <a-select showSearch option-filter-prop="children" placeholder="请选择" v-decorator="['transferPerson',{rules:[{required:true,message:'请选择移交人员'}]}]">
                      <a-select-option v-for="(item,key) in supervisePersonList" :key="key" :value="item.userId">{{item.name}}</a-select-option>
                   </a-select>
               </a-form-item>
              
               <a-row class="form-btn-group">
                   <a-button class="btn-save" :disabled="submitLoadding" :loadding="submitLoadding" @click="transferPersonSubmit"><a-icon v-if="!submitLoadding" type="save" />提交</a-button>
                   <a-button class="btn-cancel"  @click="selectUserVisible = false">取消</a-button>
               </a-row>
           </a-form>
        </a-modal>

    </section>
</template>

<script>
// import selectUserCascader from '@/components/select-user-cascader'
// import selectUser from '@/components/selectUser'
import previewImage from '@/components/previewImage'
import upload from '@/components/upload'
import moment from 'moment'
export default {
    data(){
        return{
            taskInfo:{},
            fileLists:[],
            supervisePersonList:[],
            selectUserVisible:false,
            supervisePerson:'',
        }
    },
    components:{previewImage,upload},
    beforeCreate(){
        this.form = this.$form.createForm(this)
        this.transferForm = this.$form.createForm(this)
    },
    created(){
        this.querySafeOccupationUserList()
        this.querySafeJobDetails()
    },
    computed:{
        rid(){
            return this.$route.query.id
        },
        actionType(){
            return this.$route.query.type
        },
        userId(){
            return this.$store.state.userInfo.userId
        },
        submitLoadding(){
            return this.$store.state.submitLoadding
        }
    },
    methods:{
         //监管职务下的人员列表
        querySafeOccupationUserList(){
            this.$api.querySafeOccupationUserList().then(res =>{
                if(res.code === 200){
                    this.supervisePersonList = res.data || []
                }
            })
        },
        querySafeJobDetails(){
            this.$api.querySafeJobDetails(this.rid).then(res =>{
                if(res.code === 200){
                    this.taskInfo = res.data || {}
                    // this.taskInfo.file = this.taskInfo.file ? JSON.parse(this.taskInfo.file) : []
                    this.taskInfo.file = this.taskInfo.file ? this.taskInfo.file.split(',') : []
                    this.taskInfo.superviseList = (res.data && res.data.superviseList) || []
                    this.taskInfo.superviseList.forEach(item =>{
                        item.supervisePhoto = item.supervisePhoto ? item.supervisePhoto.split(',') : []
                    })
                }
            })
        },
        getFile(file){
            this.fileLists.push(file.codeUrl)
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    supervisePhoto:this.fileLists.length ? this.fileLists.join(',') : ''
                })
            })
            
        },
        deleteFile(file){
            this.fileLists = this.fileLists.filter(item => item !== file)
            this.$nextTick(() =>{
                this.form.setFieldsValue({
                    supervisePhoto:this.fileLists.length ? this.fileLists.join(',') : ''
                })
            })
        },
        //移交
        showSelectUserModal(list){
           
            let superviseList = this.taskInfo.superviseList.map(item => item.supervisePerson)
            this.supervisePersonList = this.supervisePersonList.filter(item => !superviseList.includes(item.userId))
            this.supervisePerson = list.supervisePerson
            this.selectUserVisible = true
        },
        transferPersonSubmit(){

            this.transferForm.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    let params = {
                        supervisePerson:this.supervisePerson,
                        safeJobId:this.rid,
                        transferPerson:formData.transferPerson
                    }
                    this.$api.safeJobSupervise(params).then(res =>{
                        if(res.code === 200){
                            this.selectUserVisible = false
                            this.$router.back()
                        }
                    })
                }
            })
        },
        
        submit(list){
            let supervisePerson = list.supervisePerson
            this.form.validateFields((err,formData) =>{
                if(!err){
                    this.$store.commit('SET_SUBMITLOADDING',true)
                    let params = {
                        superviseTime:  moment(new Date()).format('YYYY-MM-DD HH:mm:SS'),
                        superviseDescription:formData.superviseDescription,
                        supervisePhoto:formData.supervisePhoto,
                        supervisePerson
                    }
                 
                    params.safeJobId = this.rid
                    this.$api.safeJobSupervise(params).then(res =>{
                        if(res.code === 200){
                            this.$router.back()
                        }
                    })
                }
            })
        }
    }
}
</script>